'use client';

import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import Link from '@/components/core/Link';

// import { useAppRouter } from '@/components/hooks/useAppRouter';
import { useGameModalStore } from '@/store/zustand';

import { LobbyCate } from '@/constant/data';
import { gameMenuDesktop } from '@/constant/gameMenu';

const GameMenuHeader = ({
  // providersActive,
  id,
  t,
}: {
  id?: any;
  t?: any;
}) => {
  const { gameInfo, isLargeSize } = useGameModalStore();
  const [visible, setVisible] = useState<string | number>(-1);
  const idActive = Array.isArray(id) ? LobbyCate?.[id?.[1]]?.slug : id;

  const isMounted = useRef(false);

  useEffect(() => {
    let timer: null | NodeJS.Timeout = null;
    timer = setTimeout(() => {
      isMounted.current = true;
    }, 500);

    return () => {
      if (timer) clearTimeout(timer);
      isMounted.current = false;
    };
  }, []);

  return (
    <div
      className={clsx(
        'bg-linear-9 w-full',
        !!gameInfo.src &&
          isLargeSize &&
          'pointer-events-none h-0 opacity-0 transition-all'
      )}
    >
      <div
        className='container-pc flex h-[42px] min-w-[833px] flex-row  items-center text-[#424242]'
        id='gameMenu'
        onMouseLeave={() => {
          setVisible(-1);
        }}
      >
        {gameMenuDesktop.map((_: any, index: number) => {
          const isActive = idActive === _.slug;
          const isVisible = _.games?.length > 0 && visible === _.slug;
          return (
            <div key={_.slug || index} className='shrink-0'>
              {index === 0 && (
                <div className='relative z-10'>
                  <div className='absolute -top-2 right-0 h-[16px] w-[22px]'>
                    <div className='text-8 absolute -top-[27px] left-[3px] z-10 h-auto w-[22px] font-bold text-white'>
                      Hot
                    </div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='22'
                      height='16'
                      viewBox='0 0 22 16'
                      fill='none'
                    >
                      <path
                        className='svg-color'
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M2 0C0.895431 0 0 0.895431 0 2V9C0 10.1046 0.89543 11 2 11H14.1818L11 16L20 11C21.1046 11 22 10.1046 22 9V7.27273V2C22 0.895431 21.1046 0 20 0H2Z'
                        fill='#C00B0B'
                      />
                    </svg>
                  </div>
                </div>
              )}
              <div
                className={clsx(
                  'after:bg-menu-active group relative h-full after:absolute after:bottom-0 after:left-0 after:h-0 after:w-full after:border-transparent after:transition-all after:content-[""] ',
                  {
                    'after:!border-yellow-orange !group !relative !left-0 !h-full !transition-all !content-[""] after:!absolute after:!bottom-0 after:!h-full after:!w-full':
                      true,
                  },
                  'overflow-y-hidden'
                )}
              >
                <div
                  className={clsx(
                    'text-gray-text text-1xs lxl:text-1mxs xl:text-1mxs lg:text-2mxs md:text-2mxs relative z-[1] flex h-full items-center justify-center text-center group-hover:cursor-pointer group-hover:text-white',
                    {
                      'group-hover:!text-ixs gameMenuItemVisible group-hover:!text-white':
                        isVisible,
                    }
                  )}
                >
                  <div
                    className={clsx(
                      'hover:bg-linear group h-[42px]',
                      'text-16 cursor-hand relative flex h-11 w-fit shrink-0 flex-row items-center px-[6px] font-semibold transition-all',
                      {
                        'bg-linear': isActive,
                      }
                    )}
                    onMouseEnter={() => {
                      const isGameSelectSearchHidden = document.querySelector(
                        '.gameSelectDropdown'
                      );

                      if (
                        isGameSelectSearchHidden &&
                        !isGameSelectSearchHidden?.classList?.contains(
                          'ant-select-dropdown-hidden'
                        )
                      ) {
                        setVisible(-1);
                        return;
                      }

                      setTimeout(() => {
                        if (isMounted.current) setVisible(_.slug);
                      }, 100);
                    }}
                  >
                    <Link
                      prefetch
                      className='text-16 whitespace-nowrap font-semibold !text-black hover:!text-black'
                      href={`${_.link}`}
                      onClick={() => {
                        setTimeout(() => {
                          setVisible(-1);
                        }, 200);
                      }}
                    >
                      <div className='text-normal flex items-center p-3 font-medium uppercase'>
                        {/* <img
                      className={clsx('mr-1 mr-[2px] w-[30px] transition-all')}
                      src={_.icon}
                      alt='icon'
                    /> */}
                        {t(_.label)}
                      </div>
                    </Link>
                  </div>{' '}
                </div>
              </div>
              {/* TODO: TBU */}
            </div>
          );
        })}
        {/* TODO: overlay bottom */}
        {visible !== -1 && (
          <div
            onMouseEnter={() => {
              setVisible(-1);
            }}
            className='overlay-game-bottom pointer-events-none fixed inset-0 bottom-0 left-0 right-0 z-[9999] h-full w-full overflow-hidden'
          />
        )}
        <div className='ml-auto flex'>
          <div className='hover:bg-linear flex h-[42px] w-fit flex-row items-center px-[6px] transition-all'>
            <Link
              prefetch
              className='text-16 inter-semibold whitespace-nowrap !text-black'
              href='/promotion'
            >
              <span className='border-l border-black pl-4 font-semibold hover:text-black'>
                {t('header.menuItem8')}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(GameMenuHeader);
