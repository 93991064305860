import CasinoGames from '@/app/[lng]/(home)/components/Mobile/components/CasinoGames';
import FishingGames from '@/app/[lng]/(home)/components/Mobile/components/FishingGames';
import KenoGames from '@/app/[lng]/(home)/components/Mobile/components/KenoGames';
import SlotGames from '@/app/[lng]/(home)/components/Mobile/components/SlotGames';
import SportGames from '@/app/[lng]/(home)/components/Mobile/components/SportGames';
import {
  casinoItems,
  fishing,
  liveCasinoItems,
  lottery,
  PARTNER_PROVIDER,
  slotItems,
  sports,
} from '@/constant/data';

export const gameMenuMobile = [
  // {
  //   icon: '/img/menu/active/hot.png',
  //   iconHide: '/img/menu/fade/hot.png',
  //   display: '17',
  //   label: 'header.menuItem13',
  //   slug: 'hot',
  //   component: (props: JSX.IntrinsicAttributes) => <HotGames {...props} />,
  // },
  {
    icon: '/img/menu/active/slots.png',
    iconHide: '/img/menu/fade/slots.png',
    display: '3',
    label: 'header.menuItem3',
    slug: 'slots',
    component: (props: JSX.IntrinsicAttributes) => <SlotGames {...props} />,

    mobileBanner: '/img/game/banner/mb_slot.png',
  },
  {
    icon: '/img/menu/active/live.png',
    iconHide: '/img/menu/fade/live.png',
    display: '6',
    label: 'header.menuItem1',
    slug: 'live-casino',
    component: (props: JSX.IntrinsicAttributes) => <CasinoGames {...props} />,
    mobileBanner: '/img/game/banner/mb_live-casino.png',
  },
  {
    icon: '/img/menu/active/skill2.png',
    iconHide: '/img/menu/fade/skill2.png',
    display: '2',
    label: 'header.menuItem18',
    slug: 'tables',
    component: (props: JSX.IntrinsicAttributes) => <SlotGames {...props} />,

    mobileBanner: '/img/game/banner/mb_table.png',
  },
  {
    icon: '/img/menu/active/sport.png',
    iconHide: '/img/menu/fade/sport.png',
    label: 'header.menuItem4',
    slug: 'sports',
    games: sports,
    component: (props: JSX.IntrinsicAttributes) => <SportGames {...props} />,
  },

  // {
  //   icon: '/img/menu/active/skill2.png',
  //   iconHide: '/img/menu/fade/skill2.png',
  //   display: '2,3',
  //   label: 'header.menuItem20',
  //   slug: 'casino',
  //   component: (props: JSX.IntrinsicAttributes) => <SlotGames {...props} />,
  // },
  {
    icon: '/img/menu/active/keno.png',
    iconHide: '/img/menu/fade/keno.png',
    display: '7',
    label: 'header.menuItem12',
    slug: 'lottery',
    component: (props: JSX.IntrinsicAttributes) => <KenoGames {...props} />,

    mobileBanner: '/img/game/banner/mb_lottery.png',
  },
  {
    icon: '/img/menu/active/fish.png',
    iconHide: '/img/menu/fade/fish.png',
    display: '9',
    label: 'header.menuItem7',
    slug: 'fishing',
    component: (props: JSX.IntrinsicAttributes) => <FishingGames {...props} />,

    mobileBanner: '/img/game/banner/mb_fishing.png',
  },

  // {
  //   icon: '/img/menu/active/e-sport.png',
  //   iconHide: '/img/menu/fade/sport.png',
  //   display: '104',
  //   label: 'header.menuItem6',
  //   slug: 'esport',
  //   component: (props: JSX.IntrinsicAttributes) => <ESportGames {...props} />,
  // },

  // {
  //   icon: '/img/menu/active/skill.png',
  //   iconHide: '/img/menu/fade/skill.png',
  //   display: '58',
  //   label: 'header.menuItem18',
  //   slug: 'table-game',
  //   component: (props: JSX.IntrinsicAttributes) => <TableGames {...props} />,
  // },

  // {
  //   icon: '/img/menu/active/cock-fight.png',
  //   iconHide: '/img/menu/fade/cock-fight.png',
  //   display: '',
  //   label: 'header.menuItem15',
  //   slug: 'cock-fight',
  //   component: (props: JSX.IntrinsicAttributes) => (
  //     <CockFightGames {...props} />
  //   ),
  //   isOpenNewTab: true,
  //   games: cockfight,
  // },
];

export const gameMenuDesktop = [
  // {
  //   label: 'header.menuItem13',
  //   slug: 'hot',
  //   display: 'hot',
  //   link: '/game/hot',
  //   games: hotItems,
  //   icon: '/img/menu/active2/hot.png',
  // },
  {
    label: 'header.menuItem3',
    slug: 'slots',
    display: 3,
    link: '/game/slots',
    games: slotItems,
    icon: '/img/menu/active2/slots.png',
    banner: '/img/game/banner/slot.png',
    mobileBanner: '/img/game/banner/mb_slot.png',
  },
  {
    label: 'header.menuItem1',
    slug: 'live-casino',
    display: 6,
    link: '/game/live-casino',
    games: liveCasinoItems,
    icon: '/img/menu/active2/live.png',
    banner: '/img/game/banner/live-casino.png',
    mobileBanner: '/img/game/banner/mb_live-casino.png',
  },
  {
    label: 'header.menuItem18',
    slug: 'tables',
    display: 2,
    link: '/game/tables',
    games: [...casinoItems].concat({
      key: 'spribe',
      icon: '/img/menu/table/spribe.png',
      img: '/img/game/lottery/banner_2.png',
      mobileImg: '/img/game/lottery/banner_mb_2.png',
      partner_provider: PARTNER_PROVIDER.spribe_turbo,
      // lobbyCate: 2,
      lobbyCate: '2',
    }),
    icon: '/img/menu/active2/slots.png',
    banner: '/img/game/banner/tables.png',
    mobileBanner: '/img/game/banner/mb_casino.png',
  },
  {
    label: 'header.menuItem4',
    slug: 'sports',
    link: '/game/sports',
    games: sports,
    icon: '/img/menu/active2/sport.png',
  },

  {
    label: 'header.menuItem7',
    slug: 'fishing',
    display: 9,
    link: '/game/fishing',
    games: fishing,
    icon: '/img/menu/active2/fish.png',
    banner: '/img/game/banner/fishing.png',
    mobileBanner: '/img/game/banner/mb_fishing.png',
  },
  {
    label: 'header.menuItem12',
    slug: 'lottery',
    link: '/game/lottery',
    games: lottery,
    icon: '/img/menu/active2/keno.png',
    banner: '/img/game/banner/lottery.png',
    mobileBanner: '/img/game/banner/mb_lottery.png',
    display: 7,
  },
  // TODO: TBU
  // {
  //   label: 'header.menuItem6',
  //   slug: 'esport',
  //   link: '/game/esport',
  //   games: esports,
  //   icon: '/img/menu/active2/e-sport.png',
  // },
  // {
  //   label: 'header.menuItem18',
  //   slug: 'table-game',
  //   link: '/game/table-game',
  //   display: '2',
  //   games: table,
  //   icon: '/img/menu/active2/skill.png',
  //   banner: '/img/game/banner/table.png',
  //   mobileBanner: '/img/game/banner/mb_table.png',
  // },
  // {
  //   label: 'header.menuItem15',
  //   slug: 'cock-fight',
  //   link: '/game/cock-fight',
  //   games: cockfight,
  //   icon: '/img/menu/active2/cock-fight.png',
  //   banner: '/img/game/banner/cock-fight.png',
  //   mobileBanner: '/img/game/banner/mb_cock-fight.png',
  // },
];
